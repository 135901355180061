import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Typography,
  Upload,
} from 'antd';
import React, { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  Contract,
  CreatePayment,
  Customer,
  Supplier,
  UpdateContract,
  get,
} from '../../../../api';
import type { UploadFile } from 'antd/es/upload/interface';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import { useUser } from '../../../../hooks';
import moment from 'moment';
import { CanShow } from '../../../../components';
type ContractsFormProps = {
  type: string;
  id?: number | null;
  add: (v: CreatePayment) => void;
  update: (v: UpdateContract) => void;
};

const InvoicePaymentForm: React.FC<ContractsFormProps> = (props) => {
  const { type, id, add, update } = props;
  const [form] = Form.useForm();
  const statusOptions = [
    { label: 'VIGENTE', value: 0 },
    { label: 'EN ESPERA', value: 1 },
    { label: 'COMPLETADO', value: 2 },
    { label: 'EXPIRADO', value: 3 },
  ];
  const { user } = useUser();
  const isLoadingData = false;
  // const { isLoading: isLoadingData, data } = useQuery<Contract>([type], () =>
  //   get<Contract>('/contracts/' + id).then((e) => e.response)
  // );

  // useEffect(() => {
  //   if (type !== 'add' && id && data) {
  //     const date = moment.unix(data.created_at);
  //     form.setFieldsValue({
  //       contractName: data.name,
  //       contractDescription: data.description,
  //       supplier: data.supplier_id,
  //       customer: data.customer_id,
  //       month: date,
  //       status: data.status,
  //     });
  //   }
  //   // setIsLoading(false);
  // }, [form, data, id, type]);

  const fileList: UploadFile[] = [
    {
      uid: '-1',
      name: '',
      status: 'done',
      url: '',
      thumbUrl:
        '',
    },
  ];
  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    // Can not select days before today and today
    return current && current > dayjs().endOf('day');
  };
  const addContractFunction = async (values: any) => {
    if (type === 'add' && user) {
      // AGREGAR CONTRATOS
      let paymentRequest: CreatePayment = {
        payment_dt: values.payment_dt,
        amount: values.amount,
        file: ''
      };
      add(paymentRequest);
    } else {
      // EDITAR CONTRATOS
      if (!id) return;
      let contractRequest: UpdateContract = {
        id: id,
        name: values.contractName,
        description: values.contractDescription,
        status: values.status,
      };
      update(contractRequest);
    }
  };

  return (
    <Spin
      spinning={isLoadingData }
      tip={<p>Cargando...</p>}
    >
      <Form
        name="contracts"
        layout="vertical"
        form={form}
        onFinish={addContractFunction}
      >
        <Divider />
        <Row style={{ display: 'flex', justifyContent: 'center' }}>
          <Col xs={20}>
            <Row gutter={16}>
              <Col xs={24} md={24}>
                <Form.Item
                  label="Fecha de pago:"
                  name="payment_dt"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor seleccione mes y año',
                    },
                  ]}
                >
                  <DatePicker
                    disabled={type !== 'add'}
                    picker="date"
                    format={'DD/MM/YYYY'}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={24}>
                <Form.Item
                  label="Total a pagar:"
                  name="amount"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingrese un monto',
                    },
                  ]}
                >
                  <Input placeholder="Ingresa tu información" />
                </Form.Item>
              </Col>

              <Col xs={24} md={8}>
                <Form.Item name="file">
                  <Upload
                    accept=".pdf"
                    defaultFileList={[...fileList]}
                    listType="picture"
                    maxCount={1}
                    style={{ width: '100% !important' }}
                    customRequest={({ file, onSuccess, onError }) => {
                      if (onSuccess) {
                        onSuccess('ok');
                        // setFileBlob(file as Blob);
                      }
                    }}
                  >
                    <Button
                      style={{ width: 195, marginLeft: 0 }}
                      className="btn-download"
                      // icon={<UploadOutlined />}
                    >
                      Comprobante de pago
                    </Button>
                  </Upload>
                </Form.Item>
              </Col>

              {type !== 'add' && (
                <Col xs={24} md={24}>
                  <Form.Item
                    label="Estatus:"
                    name="status"
                    rules={[
                      {
                        required: true,
                        message: 'Por favor seleccione estatus',
                      },
                    ]}
                  >
                    <Select
                      placeholder="Selecciona una opción"
                      disabled={type === 'add'}
                      allowClear
                      showSearch
                      options={statusOptions}
                      optionFilterProp="children"
                    />
                  </Form.Item>
                </Col>
              )}
            </Row>

            <Row justify="end">
              <Form.Item>
                <Button
                  htmlType="submit"
                  className="btn-add"
                  disabled={type === 'watch' }//|| isLoadingData}
                >
                  Guardar
                </Button>
              </Form.Item>
            </Row>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default InvoicePaymentForm;
