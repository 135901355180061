import { Button, Row, Tag, Tooltip, Typography } from 'antd';
import type { ColumnType, ColumnsType } from 'antd/es/table';
import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  FileOutlined,
  DollarOutlined,
  CheckCircleOutlined,
  EditOutlined,
} from '@ant-design/icons';

import { Contract } from '../../../../api';
import Table from '../../../../components/general/table';
import { ExportExcelProps } from '../../../../helpers/excel';
import InvoicePaymentForm from '../detail';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import { CanShow } from '../../../../components';
import { ExportPdfProps } from '../../../../helpers/pdf';

const label = 'pago';
let route = `/invoice`;
const titlePdf = 'Reporte de contratos';

type GenerateTableColumnProps = {
  getColumnSearchProps: (propName: string) => ColumnType<Contract>;
  showModal: (type: string, id: number | null) => void;
  handleDelete: (id: number) => void;
};

const InvoicePaymentsList = () => {
  const { Text } = Typography;
  const navigate = useNavigate();
  const { id } = useParams();
  route = `/invoices/${id}/payments`
  const generateTableColumns: (
    props: GenerateTableColumnProps
  ) => ColumnsType<Contract> = (props) => {
    const { getColumnSearchProps, showModal } = props;

    return [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        ...getColumnSearchProps('id'),
        render: (id) => <Text>{id}</Text>,
      },
      {
        title: 'Fecha de Pago',
        dataIndex: 'payment_dt',
        key: 'payment_dt',
        ...getColumnSearchProps('supplier_name'),
        render: (payment_dt) => <Text>{new Date(payment_dt).toLocaleDateString()}</Text>,
      },
      {
        title: 'Monto',
        dataIndex: 'amount',
        key: 'amount',
        ...getColumnSearchProps('name'),
        render: (amount) => (
          <Text>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(amount)}</Text>
        ),
      },
      {
        title: 'Usuario',
        dataIndex: 'CreatedBy',
        key: 'CreatedBy',
        ...getColumnSearchProps('name'),
        render: ({username}) => (
          <Text>{username.charAt(0).toUpperCase() + username.slice(1)}</Text>
        ),
      },
      {
        title: 'Ultima modificaciòn',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        ...getColumnSearchProps('name'),
        render: (updatedAt) => (
          <Text>{new Date(updatedAt).toLocaleDateString()}</Text>
        ),
      },
      // {
      //   title: 'Estatus',
      //   dataIndex: 'name',
      //   key: 'name',
      //   ...getColumnSearchProps('name'),
      //   render: (name) => (
      //     <Text>{name.charAt(0).toUpperCase() + name.slice(1)}</Text>
      //   ),
      // },
      // {
      //   title: 'Archivos de facturas',
      //   key: 'sup',
      //   align: 'center',
      //   render: (contract, record) => (
      //     <>
      //     {record.status === 2 && (
      //         <Tooltip title="Archivos Facturación">
      //           <Button
      //             style={{
      //               marginLeft: 10,
      //               backgroundColor: '#1890ff',
      //               borderColor: '#1890ff',
      //             }}
      //             type="primary"
      //             shape="default"
      //             size="small"
      //             icon={<FileOutlined />}
      //             onClick={() => {
      //               navigate(`/contract/invoice-files/${contract.id}`);
      //             }}
      //           />
      //       </Tooltip>
      //       )}
      //     </>
      //   ),
      // },
     
    ];
  };

  const generateExportExcelProps: () => ExportExcelProps = () => ({
    fileName: 'Reporte_Contratos',
    sheetName: 'Contratos',
    tableName: 'Tabla de contratos',
    columnNames: [
      { label: 'No. contrato', key: 'id' },
      { label: 'Proveedor', key: 'supplier_name' },
      { label: 'Nombre', key: 'name' },
      { label: 'Descripción', key: 'description' },
      { label: 'Estatus', key: 'status_description' },
      { label: 'Creado por:', key: 'customer_name' },
    ],
  });

  const pdfColumns: ExportPdfProps[] = [
    { header: 'No. contrato', key: 'id' },
    { header: 'Proveedor', key: 'supplier_name' },
    { header: 'Nombre', key: 'name' },
    { header: 'Descripción', key: 'description' },
    { header: 'Estatus', key: 'status_description' },
    { header: 'Creado por:', key: 'customer_name' },
  ];

  const contrctTableProps = {
    label,
    route,
    generateTableColumns,
    generateExportExcelProps,
    detailForm: InvoicePaymentForm,
    pdfColumns,
    titlePdf,
  };
  const ContractsTable = Table<Contract>(contrctTableProps);

  return ContractsTable;
};

export default InvoicePaymentsList;
