import { Button, Col, Divider, Form, Input, Radio, Row, Spin } from 'antd';
import React, { useEffect } from 'react';
import Password from '../../../../components/general/password';
import { useQuery } from '@tanstack/react-query';
import { CreateUser, User, get } from '../../../../api';

type UserFormProps = {
  type: string;
  id?: number | null;
  add: (v: CreateUser) => void;
  update: (v: User) => void;
};

const UserForm: React.FC<UserFormProps> = (props) => {
  const { type, id, add, update } = props;
  const [form] = Form.useForm();
  
  // TODO: Deberia ser un servicio
  const roleOptions = [
    { label: 'ADMIN', value: 1 },
    // { label: 'PROVEEDOR', value: 3 },
  ];
  const { isLoading: isLoadingData, data } = useQuery<User>([type], () =>
    get<User>('/users/' + id).then((e) => e.response)
  );

  useEffect(() => {
    if (type !== 'add' && id) {
      form.setFieldsValue({
        email: data?.email,
        username: data?.username,
        roleId: data?.roleId,
      });
    }
  }, [form, data, id, type]);

  const addUserFunction = async (values: any) => {
    if (type === 'add') {
      // AGREGAR USUARIO
      let userRequest: CreateUser = {
        email: values.email,
        password: values.password,
        roleId: values.roleId,
        username: values.username,
      };
      add(userRequest);
    } else {
      // EDITAR USUARIO
      values['id'] = id;
      update(values);
    }
  };

  return (
    <Spin spinning={isLoadingData} tip={<p>Cargando...</p>}>
      <Form
        name="add-promo"
        layout="vertical"
        form={form}
        onFinish={addUserFunction}
      >
        <Divider />
        <Row style={{ display: 'flex', justifyContent: 'center' }}>
          <Col xs={20}>
            <Form.Item
              label="Correo electrónico:"
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese un email',
                },
              ]}
            >
              <Input
                type="email"
                placeholder="Ingresa tu información"
                disabled={type !== 'add'}
              />
            </Form.Item>
            {type === 'add' && <Password type="adduser" />}

            <Form.Item
              label="Nombre de usuario:"
              name="username"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese un nombre',
                },
              ]}
            >
              <Input
                placeholder="Ingresa tu información"
                disabled={type === 'watch'}
              />
            </Form.Item>

            <Form.Item
              label="Rol de usuario:"
              name="roleId"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese un rol',
                },
              ]}
            >
              <Radio.Group
                disabled={
                  type === 'watch'
                }
                options={roleOptions}
                // initialValues={[1]}
              ></Radio.Group>
            </Form.Item>
            <Row justify="end">
              <Form.Item>
                <Button
                  htmlType="submit"
                  className="btn-add"
                  disabled={type === 'watch' || isLoadingData}
                >
                  Guardar
                </Button>
              </Form.Item>
            </Row>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default UserForm;
