import { Link } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import {
  HomeOutlined,
  UserOutlined,
  FileProtectOutlined,
  ToolOutlined,
  DollarOutlined,
  BarChartOutlined
} from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import './menu-sider.scss';
import CanShow from '../../can-show';

const { SubMenu } = Menu;

const MenuSider = ({
  menuCollapsed,
  roles = [],
}: {
  menuCollapsed: boolean | undefined;
  roles: string[];
}) => {
  const { Sider } = Layout;
  const location = useLocation();

  return (
    <Sider
      className="admin-sider"
      collapsed={menuCollapsed}
      collapsible
      breakpoint="md"
      collapsedWidth="0"
      width={220}
      style={{ position: 'fixed' }}
    >
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={[location.pathname]}
        selectedKeys={[location.pathname]}
      >
        <Menu.Item key="/">
          <Link to="/">
            <HomeOutlined />
            <span className="nav-text">Inicio</span>
          </Link>
        </Menu.Item>

        <Menu.Item key="/contracts">
          <Link to="/contracts">
            <FileProtectOutlined />
            <span className="nav-text">Contratos</span>
          </Link>
        </Menu.Item>

        {/* <SubMenu key="/finances" icon={<BarChartOutlined />} title="Finanzas">
        <CanShow roles={['Supplier']}>
            <Link to="/finances">
              <BarChartOutlined />
              <span className="nav-text">Finanzas</span>
            </Link>
            </CanShow>
            </SubMenu>

        <CanShow roles={['Admin']}>
            <SubMenu key="/finances" icon={<BarChartOutlined />} title="Finanzas">
              <Menu.Item key="/finances/CXP">
                <Link to="/finances/CXP">
                  <DollarOutlined />
                  <span className="nav-text">CxP</span>
                </Link>
              </Menu.Item>
            </SubMenu>
          </CanShow> */}
          <Menu.Item key="/finances">
                <Link to="/finances">
                  <BarChartOutlined />
                  <span className="nav-text">Finanzas</span>
                </Link>
          </Menu.Item>
          <Menu.Item key="/administration">
          <CanShow roles={['Admin', 'Financial Advisor']}>
            <Link to="/administration">
              <ToolOutlined />
              <span className="nav-text">Administración</span>
            </Link>
          </CanShow>
        </Menu.Item>

        
      </Menu>
    </Sider>
  );
};

export default MenuSider;
