import { Alert, Layout } from 'antd';
import GreetingsTop from '../../components/menu/greetings-top';
import './home.scss';
import CardsHome from '../../components/menu/cards-home';
import { useUser } from '../../hooks';
import { Supplier } from '../../api';

const Home = () => {
  const { user } = useUser();
  return (
    <Layout className="admin">
      <GreetingsTop />
      {user &&
        user.role_description === 'Supplier' &&
        !(user.extendedInfo as Supplier).tax_certificate_id && (
          <Alert
            style={{ margin: 20 }}
            message="Debes agregar registro de Constancia Fiscal vigente."
            banner
          />
        )}
      <CardsHome></CardsHome>
    </Layout>
  );
};

export default Home;
