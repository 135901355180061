import React, {
  ReactElement,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Button,
  Col,
  Row,
  Spin,
  Table,
  Modal as ModalAntd,
  Input,
  Space,
} from 'antd';
import {
  FileExcelFilled,
  FilePdfFilled,
  PlusCircleOutlined,
  ExclamationCircleOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import './table.scss';
import Modal from '../modal';
import type { ColumnType, FilterConfirmProps } from 'antd/es/table/interface';
import type { InputRef } from 'antd';
import { useMutation, useQuery } from '@tanstack/react-query';
import { get, post, put, del } from '../../../api';
import Highlighter from 'react-highlight-words';
import { ExportExcelProps, exportToExcel } from '../../../helpers/excel';
import CanShow from '../../can-show';
import ApiResponse from '../../../api/models/response';
import { ExportPdfProps, exportToPdf } from '../../../helpers/pdf';

type TableParams = {
  label: string;
  route: string;
  detailForm: React.FC<{
    type: string;
    id: number | null;
    add: (v: any) => void;
    update: (v: any) => void;
  }>;
  generateTableColumns: (props: any) => any[];
  generateExportExcelProps: () => ExportExcelProps;
  pdfColumns: ExportPdfProps[];
  titlePdf: string;
};

const TableComponent = <T,>(props: TableParams) => {
  const { confirm } = ModalAntd;
  const {
    label,
    route,
    detailForm: DetailForm,
    generateTableColumns,
    generateExportExcelProps,
    pdfColumns,
    titlePdf,
  } = props;

  let getAction = () => get<T[]>(route).then((e) => e.response);

  const query = useQuery<T[]>([label], getAction);

  const defaultMutationOpts = {
    onSuccess: () => {
      query.refetch();
    },
  };

  let addAction = (data: any) => post<T>(`${route}`, data, true);
  const addMutation = useMutation([label], addAction, defaultMutationOpts);

  let updateAction = (data: any) => put<T>(`${route}`, data, true);
  const updateMutation = useMutation(
    [label],
    updateAction,
    defaultMutationOpts
  );

  let delAction = (id: number) => del(`${route}/${id}`, undefined, true);
  const deleteMutation = useMutation([label], delAction, defaultMutationOpts);
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [titleModal, setTitleModal] = useState('');
  const [contextModal, setContextModal] = useState<ReactElement | null>(null);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<InputRef>(null);

  type DataIndex = keyof T;

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex as string);
  };
  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<T> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Buscar`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters);
              handleSearch(selectedKeys as string[], confirm, dataIndex);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Limpiar
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Cerrar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      (record[dataIndex] as string)
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const onSuccessAction = useCallback(() => {
    setModalIsVisible(() => false);
  }, []);

  const addCallback = useCallback(
    (e: ApiResponse<T>) =>
      addMutation.mutate(e, {
        onSuccess: onSuccessAction,
      }),
    [addMutation, onSuccessAction]
  );

  const updateCallback = useCallback(
    (e: ApiResponse<T>) =>
      updateMutation.mutate(e, {
        onSuccess: onSuccessAction,
      }),
    [updateMutation, onSuccessAction]
  );

  const showModal = (type: string, id: number | null) => {
    setModalIsVisible(true);
    setTitleModal(
      `${
        type === 'add' ? 'Agregar' : type === 'edit' ? 'Editar' : 'Ver'
      } ${label}`
    );
    if (type === 'repse') {
      setTitleModal(`Verificar REPSE`);
    }
    setContextModal(() => (
      <DetailForm
        type={type}
        id={id}
        add={addCallback}
        update={updateCallback}
      />
    ));
  };
  const handleDelete = (id: number) => {
    confirm({
      title: `Eliminar ${label}`,
      icon: <ExclamationCircleOutlined />,
      content: `¿Estás seguro que deseas borrar el ${label} seleccionado?, no podrá recuperarlo después.`,
      okText: 'Sí',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteRow(id);
      },
      onCancel() {},
    });
  };
  const deleteRow = (id: number) => {
    deleteMutation.mutate(id);
  };
  const columns = generateTableColumns({
    getColumnSearchProps,
    showModal,
    handleDelete,
  });
  const onExcelExportClicked = React.useCallback(() => {
    if (query.data !== undefined) {
      const props = generateExportExcelProps();
      exportToExcel<T>(query.data, props);
    }
  }, [query.data, generateExportExcelProps]);

  function mapearArreglo(
    arreglo1: any[],
    arreglo2: any[]
  ): { [key: string]: string | number }[] {
    const resultado: { [key: string]: string | number }[] = [];

    for (let i = 0; i < arreglo1.length; i++) {
      const objeto1 = arreglo1[i];
      const objetoResultado: { [key: string]: string | number } = {};

      for (let j = 0; j < arreglo2.length; j++) {
        const columna = arreglo2[j];
        const { key } = columna;

        if (objeto1.hasOwnProperty(key)) {
          objetoResultado[key] = objeto1[key];
        }
      }

      resultado.push(objetoResultado);
    }

    return resultado;
  }
  const onPdfClicked = React.useCallback(() => {
    if (query.data !== undefined) {
      const resultadoMapeado = mapearArreglo(query.data, pdfColumns);
      exportToPdf(resultadoMapeado, pdfColumns, titlePdf);
    }
  }, [query.data, pdfColumns, titlePdf]);
  const modalMemo = useMemo(
    () => (
      <Modal
        title={titleModal}
        isVisible={modalIsVisible}
        setIsVisible={setModalIsVisible}
        width={1000}
      >
        {contextModal}
      </Modal>
    ),
    [contextModal, modalIsVisible, titleModal]
  );

  return (
    <Spin spinning={query.isLoading} tip={<p>Cargando...</p>}>
      {/* Agregar */}
      <CanShow roles={['Admin', 'Financial Advisor']}>
        <Row justify="end" style={{ marginBottom: '20px' }}>
          <Button
            className="btn-add"
            icon={<PlusCircleOutlined />}
            onClick={() => showModal('add', null)}
          >
            Agregar {label}
          </Button>
        </Row>
      </CanShow>

      {/* Tabla */}
      {query.error === null || !query.isFetching ? (
        <Table
          columns={columns}
          dataSource={query.data as any[]}
          scroll={{ x: 1000 }}
        />
      ) : null}

      {/* Botones de Export */}
      {label !== 'archivos' && (
        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Button
              icon={<FilePdfFilled style={{ fontSize: 18 }} />}
              className="btn-download"
              onClick={onPdfClicked}
              style={{ width: 150 }}
            >
              Descargar Pdf
            </Button>
            <Button
              icon={<FileExcelFilled style={{ fontSize: 18 }} />}
              className="btn-download"
              onClick={onExcelExportClicked}
              style={{ width: 160 }}
            >
              Descargar Excel
            </Button>
          </Col>
        </Row>
      )}

      {/* Modal de detalle */}
      {modalMemo}
    </Spin>
  );
};

export default TableComponent;
