import {
  Button,
  Col,
  Divider,
  Form,
  Row,
  Spin,
  Typography,
  Upload,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { ContractFile, ContractFileTable } from '../../../../api';
import { UploadFile } from 'antd/lib';
import { basePath } from '../../../../api';
import { useParams } from 'react-router-dom';

import { notification } from 'antd';
import ApiResponse from '../../../../api/models/response';

type FilesFormProps = {
  type: string;
  row: ContractFileTable;
  file?: ContractFile;
  add: (v: any) => void;
  update: (v: any) => void;
};

let keyToken = process.env.REACT_APP_LOCAL_STORAGE_TOKEN_KEY || '';

const FilesForm = (p: FilesFormProps) => {
  const { type, row, file, add, update } = p;
  const { Text } = Typography;
  const [form] = Form.useForm();
  const fileList: UploadFile[] = [];
  const [fileBlob, setFileBlob] = useState<Blob | null>(null);
  const { id } = useParams();

  if (file) {
    fileList.push({
      uid: file.id.toString(),
      name: file.name,
      status: 'done',
      url: file.fileUrl,
    });
  }

  const addFileFunction = async (values: any) => {
    if (type === 'add' && fileBlob) {
      let url = basePath + '/contracts/' + id + '/invoice-files';

      const formData = new FormData();
      let fileValues = values.file.file.originFileObj;
      formData.append(row.key, fileValues);
      let fetchParams: RequestInit = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(keyToken)}`,
        },
        method: 'POST',
        body: formData,
      };
      let httpResponse = await fetch(url, fetchParams);
      let jsonText = await httpResponse.text();
      let json = JSON.parse(jsonText) as ApiResponse<any>;
      if (json.statusCode === 200) {
        notification.success({
          message: 'Archivo subido correctamente',
        });
        let savedFile = json.response.savedFiles[0] as ContractFile;
        add(savedFile);
      }
    } else if (type === 'edit' && fileBlob) {
      let url = basePath + '/invoice-files/' + file?.id;

      const formData = new FormData();
      let fileValues = values.file.file.originFileObj;
      formData.append(row.key, fileValues);
      // formData.append('is_valid', '0');
      let fetchParams: RequestInit = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(keyToken)}`,
        },
        method: 'PUT',
        body: formData,
      };
      let httpResponse = await fetch(url, fetchParams);
      let jsonText = await httpResponse.text();
      let json = JSON.parse(jsonText) as ApiResponse<any>;
      if (json.statusCode === 200) {
        notification.success({
          message: 'Archivo editado correctamente',
        });
        let savedFile = json.response as ContractFile;
        update(savedFile);
      }
    }
  };

  return (
    <Spin spinning={false}>
      <Form name="file" form={form} onFinish={addFileFunction}>
        <Divider />
        <Row style={{ display: 'flex', justifyContent: 'center' }}>
          <Col xs={20}>
            <Row style={{ marginBottom: 20 }}>
              <Col xs={24} md={14} style={{ marginBottom: 20 }}>
                <Text style={{ fontSize: 18, fontWeight: 500 }}>
                  {/* {JSON.stringify(file)} */}
                  {file?.name_description}
                  {file === undefined && row.name_description}
                </Text>
                {(file?.name_key === 'nomina' || row?.key === 'nomina') && (
                  <Col>
                    <Text style={{ fontSize: 16, fontWeight: 500 }}>
                      Un solo documento
                    </Text>
                  </Col>
                )}
              </Col>
              <Col xs={24} md={8}>
                <Form.Item name="file">
                  <Upload
                    accept=".pdf,.xml"
                    defaultFileList={[...fileList]}
                    listType="picture"
                    maxCount={1}
                    style={{ width: '100% !important' }}
                    customRequest={({ file, onSuccess, onError }) => {
                      if (onSuccess) {
                        onSuccess('ok');
                        setFileBlob(file as Blob);
                      }
                    }}
                  >
                    <Button
                      style={{ width: 195, marginLeft: 0 }}
                      className="btn-download"
                      icon={<UploadOutlined />}
                    >
                      Subir archivo
                    </Button>
                  </Upload>
                </Form.Item>
              </Col>
            </Row>

            <Row justify="end">
              <Form.Item>
                <Button
                  htmlType="submit"
                  className="btn-add"
                  disabled={type === 'watch'}
                >
                  Guardar
                </Button>
              </Form.Item>
            </Row>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default FilesForm;
