import { Button, Row, Tag, Tooltip, Typography } from 'antd';
import type { ColumnType, ColumnsType } from 'antd/es/table';
import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  FileOutlined,
  DollarOutlined,
  CheckCircleOutlined,
  EditOutlined,
} from '@ant-design/icons';

import { Contract } from '../../../../api';
import Table from '../../../../components/general/table';
import { ExportExcelProps } from '../../../../helpers/excel';
import ContractsForm from '../detail';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { CanShow } from '../../../../components';
import { ExportPdfProps } from '../../../../helpers/pdf';

const label = 'contrato';
const route = '/invoices';
const titlePdf = 'Reporte de contratos';

type GenerateTableColumnProps = {
  getColumnSearchProps: (propName: string) => ColumnType<Contract>;
  showModal: (type: string, id: number | null) => void;
  handleDelete: (id: number) => void;
};

const ContractsInvoicesList = () => {
  const { Text } = Typography;
  const navigate = useNavigate();
  const generateTableColumns: (
    props: GenerateTableColumnProps
  ) => ColumnsType<Contract> = (props) => {
    const { getColumnSearchProps, showModal } = props;

    return [
      {
        title: 'No. contrato',
        dataIndex: 'id',
        key: 'id',
        ...getColumnSearchProps('id'),
        render: (id) => <Text>{id}</Text>,
      },
      {
        title: 'Proveedor',
        dataIndex: 'Supplier',
        key: 'Supplier',
        ...getColumnSearchProps('supplier_name'),
        render: ({company_name}) => <Text>{company_name}</Text>,
      },
      {
        title: 'Nombre',
        dataIndex: 'name',
        key: 'name',
        ...getColumnSearchProps('name'),
        render: (name) => (
          <Text>{name.charAt(0).toUpperCase() + name.slice(1)}</Text>
        ),
      },
      {
        title: 'Fecha recepción',
        dataIndex: 'Invoice',
        key: 'Invoice',
        ...getColumnSearchProps('name'),
        render: (Invoice) => (
          // <Text>{Invoice?.name.charAt(0).toUpperCase() + Invoice?.name.slice(1)}</Text>
          <Text>{new Date(Invoice?.createdAt).toLocaleDateString()}</Text>
        ),
      },
      {
        title: 'Fecha documento',
        dataIndex: 'Invoice',
        key: 'Invoice',
        ...getColumnSearchProps('name'),
        render: (Invoice) => (
          // <Text>{name.charAt(0).toUpperCase() + name.slice(1)}</Text>
          <Text>{new Date(Invoice?.doc_date).toLocaleDateString()}</Text>
        ),
      },
      {
        title: 'Tipo de documento',
        dataIndex: 'Invoice',
        key: 'Invoice',
        ...getColumnSearchProps('name'),
        render: (Invoice) => { return (
          <Text>{Invoice && Invoice.doc_type ? Invoice.doc_type.charAt(0).toUpperCase() + Invoice.doc_type.slice(1) : ''}</Text>
        )},
      },
      {
        title: 'Serie',
        dataIndex: 'Invoice',
        key: 'Invoice',
        ...getColumnSearchProps('name'),
        render: (Invoice) => (
          <Text>{Invoice && Invoice.doc_serie ? Invoice.doc_serie.charAt(0).toUpperCase() + Invoice.doc_serie.slice(1) : ''}</Text>
        ),
      },
      {
        title: 'Folio',
        dataIndex: 'Invoice',
        key: 'Invoice',
        ...getColumnSearchProps('name'),
        render: (Invoice) => (
          <Text>{Invoice && Invoice.doc_folio ? Invoice.doc_folio.charAt(0).toUpperCase() + Invoice.doc_folio.slice(1) : ''}</Text>
        ),
      },
      {
        title: 'Importe',
        dataIndex: 'Invoice',
        key: 'Invoice',
        ...getColumnSearchProps('name'),
        render: (Invoice) => (
          <Row gutter={16} style={{width: 100}}>
            <Text>{Invoice && Invoice.doc_importe ? new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(Invoice.doc_importe) : ''}</Text>
          </Row>
        ),
      },
      {
        title: 'Saldo',
        dataIndex: 'Invoice',
        key: 'Invoice',
        ...getColumnSearchProps('name'),
        render: (Invoice) => (
          <Row gutter={16} style={{ width: 100 }}>
            <Text>{Invoice && Invoice.balance ? new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(Invoice.balance) : ''}</Text>
          </Row>
        ),
      },
      {
        title: 'Moneda',
        dataIndex: 'Invoice',
        key: 'Invoice',
        ...getColumnSearchProps('name'),
        render: (Invoice) => (
          <Text>{Invoice && Invoice.doc_currency ? Invoice.doc_currency.charAt(0).toUpperCase() + Invoice.doc_currency.slice(1) : ''}</Text>
        ),
      },
      {
        title: 'Estatus',
        key: 'Invoice',
        dataIndex: 'Invoice',
        filters: [
          { text: 'VIGENTE', value: 'Vigente' },
          { text: 'EN ESPERA', value: 'En espera' },
          { text: 'COMPLETADO', value: 'Completado' },
          { text: 'EXPIRADO', value: 'Expirado' },
        ],
        onFilter: (value, record) =>
          record.status_description.startsWith(value as string),
        render: (Invoice) => (
          <Row gutter={16} style={{ width: 190 }}>
            <Tag
              color={
                Invoice?.status_description === 'Vigente'
                  ? 'yellow'
                  : Invoice?.status_description === 'En espera'
                  ? 'blue'
                  : Invoice?.status_description === 'Completado'
                  ? 'green'
                  : 'red'
              }
              key={Invoice?.status_description}
              style={{ marginBottom: 10 }}
            >
              {Invoice?.status_description.toUpperCase()}
            </Tag>
          </Row>
        ),
      },
      {
        title: 'Programación de pago',
        dataIndex: 'InvoicePayments',
        key: 'InvoicePayments',
        ...getColumnSearchProps('description'),
        render: (InvoicePayments) => (
          <Text>
            {InvoicePayments && InvoicePayments[0]?.payment_dt ? new Date(InvoicePayments[0].payment_dt).toLocaleDateString() : '-'}
          </Text>
        ),
      },
      {
        title: 'Termino de pago',
        dataIndex: 'Supplier',
        key: 'Supplier',
        // ...getColumnSearchDateProps('created_at'),
        render: ({paymentTerms}) => (
          <Text>
            {paymentTerms ? paymentTerms.charAt(0).toUpperCase() + paymentTerms.slice(1) : ''}
          </Text>
        ),
      },
      {
        title: 'Creado por',
        dataIndex: 'CreatedBy',
        key: 'CreatedBy',
        ...getColumnSearchProps('customer_name'),
        render: ({username}) => (
          <Text>
            {username}
          </Text>
        ),
      },
      {
        title: 'Archivos de facturas',
        key: 'sup',
        align: 'center',
        render: (contract, record) => (
          <>
          {record.status === 2 && (
              <Tooltip title="Archivos Facturación">
                <Button
                  style={{
                    marginLeft: 10,
                    backgroundColor: '#1890ff',
                    borderColor: '#1890ff',
                  }}
                  type="primary"
                  shape="default"
                  size="small"
                  icon={<FileOutlined />}
                  onClick={() => {
                    navigate(`/contract/invoice-files/${contract.id}`);
                  }}
                />
            </Tooltip>
            )}
          </>
        ),
      },
      {
        title: 'Pagos',
        key: 'sup',
        align: 'center',
        render: (contract, record) => (
          <>
          {record.status === 2 && (
              <Tooltip title="Pagos">
              <Button
                style={{
                  marginLeft: 10,
                  backgroundColor: '#68d78a',
                  borderColor: '#68d78a',
                }}
                type="primary"
                shape="default"
                size="small"
                icon={<DollarOutlined />}
                onClick={() => navigate(`/invoice/payments/${contract.id}`)}
              />
            </Tooltip>
            )}
          </>
        ),
      },
      {
        title: 'Acción',
        key: 'sup',
        align: 'center',
        render: (contract, record) => (
          <>
          {record.status === 2 && (
            <CanShow roles={['Admin', 'Financial Advisor']}>
              <Tooltip title="Eliminar">
              <Button
                style={{
                  marginLeft: 10,
                }}
                danger
                type="primary"
                shape="default"
                size="small"
                icon={<DeleteOutlined />}
                // onClick={() => handleDelete(user.id)}
              />
            </Tooltip>
            </CanShow>
            )}
          </>
        ),
      },
    ];
  };

  const generateExportExcelProps: () => ExportExcelProps = () => ({
    fileName: 'Reporte_Contratos',
    sheetName: 'Contratos',
    tableName: 'Tabla de contratos',
    columnNames: [
      { label: 'No. contrato', key: 'id' },
      { label: 'Proveedor', key: 'supplier_name' },
      { label: 'Nombre', key: 'name' },
      { label: 'Descripción', key: 'description' },
      { label: 'Estatus', key: 'status_description' },
      { label: 'Creado por:', key: 'customer_name' },
    ],
  });

  const pdfColumns: ExportPdfProps[] = [
    { header: 'No. contrato', key: 'id' },
    { header: 'Proveedor', key: 'supplier_name' },
    { header: 'Nombre', key: 'name' },
    { header: 'Descripción', key: 'description' },
    { header: 'Estatus', key: 'status_description' },
    { header: 'Creado por:', key: 'customer_name' },
  ];

  const contrctTableProps = {
    label,
    route,
    generateTableColumns,
    generateExportExcelProps,
    detailForm: ContractsForm,
    pdfColumns,
    titlePdf,
  };
  const ContractsTable = Table<Contract>(contrctTableProps);

  return ContractsTable;
};

export default ContractsInvoicesList;
