import React, { useEffect, useState } from 'react';
import {
  Typography,
  Row,
  Col,
  Spin,
  Form,
  Input,
  Button,
  Tag,
  Image,
} from 'antd';
import logo from '../../../assets/img/png/ap-logo-blue.png';
import { LockOutlined } from '@ant-design/icons';
import useUser from '../../../hooks/useUser';
import Password from '../password';
import { useQuery } from '@tanstack/react-query';
import { EditProfileUser, User, get, put } from '../../../api';
import './profile.scss';

export default function ProfileForm() {
  const { Title } = Typography;
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useUser();

  const { isLoading: isLoadingData, data } = useQuery<User>(['userprof'], () =>
    get<User>('/users/' + user?.id).then((e) => e.response)
  );

  useEffect(() => {
    form.setFieldsValue({
      email: data?.email,
      username: data?.username,
      role: data?.roleId,
    });
    setIsLoading(false);
  }, [form, data]);

  const editUserFunction = async (values: any) => {
    setIsLoading(true);
    if (user) {
      let userRequest: EditProfileUser = {
        id: user.id,
        currentPassword: values.currentPassword,
        password: values.password,
        passwordConfirm: values.passwordConfirm,
      };
      let resAddUser = await put<User>(
        `/users/password-change`,
        userRequest,
        true
      );
      if (resAddUser.error) {
        setIsLoading(false);
        return;
      } else {
        form.setFieldsValue({
          currentPassword: '',
          password: '',
          passwordConfirm: '',
        });
      }
    }
    setIsLoading(false);
  };

  return (
    <Spin spinning={isLoading || isLoadingData} tip={<p>Cargando...</p>}>
      <Form layout="vertical" form={form} onFinish={editUserFunction}>
        <div className="header-top__left">
          <div className="perfil-form__container-image">
            <Image width={150} src={logo} />
          </div>
          <Row
            style={{
              justifyContent: 'center',
            }}
          >
            <Form.Item
              style={{ alignItems: 'center' }}
              name="upload"
              valuePropName="fileList"
            ></Form.Item>
          </Row>
          <Row>
            <Col md={11} xs={24} style={{ marginRight: 30 }}>
              <div className="header-top__left">
                <Title level={3}>Información del perfil</Title>
                <div style={{ marginBottom: 15 }}>
                  <p>Rol:</p>
                  {user && (
                    <Tag
                      color={
                        user.role_description === 'Admin'
                          ? 'red'
                          : user.role_description === 'Financial Advisor'
                          ? 'blue'
                          : user.role_description === 'Supplier'
                          ? 'green'
                          : 'yellow'
                      }
                      key={user.role_description}
                      style={{ marginBottom: 10 }}
                    >
                      {user.role_description === 'Admin'
                        ? 'Administrador'
                        : user.role_description === 'Financial Advisor'
                        ? 'Financiero'
                        : user.role_description === 'Supplier'
                        ? 'Proveedor'
                        : 'Cliente'}
                    </Tag>
                  )}
                </div>
                <Form.Item label="Nombre:" name="username">
                  <Input placeholder="Ingresa tu información" disabled />
                </Form.Item>

                <Form.Item label="Correo electrónico:" name="email">
                  <Input placeholder="Ingresa tu información" disabled />
                </Form.Item>
              </div>
            </Col>
            <Col md={11} xs={24}>
              <div className="header-top__left">
                <Title level={3}>Cambio de contraseña</Title>
                <Form.Item
                  label="Contraseña actual:"
                  name="currentPassword"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingrese contraseña actual',
                    },
                  ]}
                >
                  <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="Ingresa tu información"
                  />
                </Form.Item>
                <Password type="supplier" />

                <Row justify="end">
                  <Button
                    htmlType="submit"
                    className="btn-add"
                    style={{ width: 170 }}
                  >
                    Actualizar contraseña
                  </Button>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </Form>
    </Spin>
  );
}
